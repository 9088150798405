var base_url = '/'; 
var datePickerPrefs = {
    format: "yyyy-mm-dd",
    language:'lt',
    startDate: Date()
    
};

var timePickerPrefs = {
    durationTime: 30,
    forceRoundTime: true,
    show2400: true,
    stopScrollPropagation:true,
    timeFormat: 'H:i'
    
};

$(document).ready(function() {
    initMagnificPopupGalleries($('.block-grid.use-gallery'));
    footer_fix();
});

function loadModal(url){
    $('#Modal2').load(url,function(){
        $('.timepicker').timepicker(timePickerPrefs);
        $('.datepicker').datepicker(datePickerPrefs);
    });
}

$(window).resize(function () {
    footer_fix();
});
//
var footer_fix = function(){
    var windowHeight = window.innerHeight;
    var header_height = $('#header_wrapper').outerHeight();
    var footer_height = $('#footer_wrapper').outerHeight();
    var menu_wrapper_height = $('#menu_wrapper').outerHeight();

    if($('.development_header').length > 0){
        header_height += $('.development_header').outerHeight();
    }
    
    if($('#slider_wrapper').length > 0){
        header_height += $('#slider_wrapper').outerHeight();
    }

    var min_height_content = windowHeight - header_height - footer_height - menu_wrapper_height;
    if($(window).width() > 767){
        $('#content_wrapper').css({minHeight:min_height_content+'px'});
    }else{
        $('#content_wrapper').css({minHeight:false});
    }
};

var equalheight = function (container) {
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;

    $(container).each(function () {
        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

var initMagnificPopupGalleries = function($galleries) {
    var count = $galleries.length;
    if(count > 0) {
        if(count > 1) {
            $galleries.each(function() {
                initMagnificPopup($(this));
            });
        } else {
            initMagnificPopup($galleries);
        }
    }
}

var initMagnificPopup = function($gallery) {
    if($gallery.length) {
        $gallery.magnificPopup({
            delegate: 'a',
            type: 'image',
            mainClass: 'mfp-with-zoom',
            image: {
                titleSrc: 'title'
            },
            gallery: {
                enabled: true,
                preload: [0,1],
                navigateByImgClick: false
            },
            zoom: {
                enabled: true,
                duration: 300,
                easing: 'ease-in-out',
                opener: function(openerElement) {
                    return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
            }
        });
    }
}


var log = function(data) {
    if (console && console.log) {
        console.log(data);
    }
}