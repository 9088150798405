function decline_request(request_id){
    $.post(base_url + 'requests/decline_request/'+request_id,function(response){
      var responseJSON = JSON.parse(response);
      //   if(responseJSON.status == 200){
          reload_page();
       // }else{
       //   
      //  }
        
    });
}

function accept_request(request_id){
    $.post(base_url + 'requests/accept_request/'+request_id,function(response){
       // if(responseJSON.status == 200){
          reload_page();
      //  }else{
      //    
      //  }
    });
}

function reload_page(){
    $('#content_wrapper').load(window.location.href + ' #content_wrapper',function(){});
}